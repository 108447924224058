.App {
  text-align: center;
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cg-toolbar-top {
  background-color: #fff;
  box-shadow: none;
  color: #111927;

  @media only screen and (min-width: 601px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #b5b8b8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.tag-options-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option-label-text {
  flex: 1;
}

.count-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 4px;
  padding:  2px 6px;
  font-size: 12px;
  line-height: 1;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.autocomplete-option {
  padding: 5px;
  cursor: pointer;
}

.tag-options {
  padding: 5px 10px;
  cursor: pointer;
}

.address-line{
  font-size: 12px;
  color: gray;
}
.cg-button-height{
  max-height: 50px;
}
.tag-in-card{
   color: #6366F1 !important;
   font-weight: 500 !important
}
.border-color-primary{
  border-color: #6366F1 !important;
}
.border-color-secondary{
  border-color: #fff !important;
}
.tag-in-card:hover {
  background-color: #F9F9FE;
}
.support-strong {
  background-color: #4caf50 !important;
  color: #fff !important; 
}
.editor-container.blockbuilder-branding {
    display: none !important;
}

.support-weak {
  background-color: #d3d3d3 !important;
  color: #000 !important;
}

.autocomplete-option:hover {
  background-color: #f0f0f0;
}

th, td {
  padding: 8px;
  text-align: left;
}

th, td {
  border-bottom: 1px solid black; /* Border color for the bottom of each row */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.avatar-container {
  position: relative;
  display: inline-block;
}

.avatar {
  width: 80px;
  height: 80px;
}

.export-saved-list-field{
  background-color: white !important;
}

.export-saved-list-field .MuiInputBase-input {
  min-height: 34px !important;
}

.export-saved-list-field .MuiInputLabel-root {
  top: 7px !important;
}

.cg-page-title-container {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.overlay {
  position: absolute;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  height: 46%;
  width: 80px;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 40px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-container:hover .overlay {
  opacity: 1;
}

.delete-button {
  color: white;
}
.cg-btn{
  white-space: nowrap;
}
.body-title{
  font-size: 0.9rem !important;
  color: #808080	;
}

.pad-r-10{
  padding-right: 10px;
}
.pad-r-16{
  padding-right: 26px;
}
.segment-close-icon{
  position: absolute;
  bottom: 13px;
  margin-left: 8px;
  right: 0;
  opacity: 0.5;
  cursor: pointer;
}

.survey-form{
  background-color: transparent !important;
}

.survey-option-close-icon{
  display: flex;
  align-items: center;
  margin-top: -4px;
  margin-left: 5px;
}

.modal-container {
  max-width: 40%;
}
.pb-20{
  padding-bottom: 20px;
}
.mb-20{
  margin-bottom: 20px !important;
}
.mb-10{
  margin-bottom: unset;
}
.margin-bottom-10px{
  margin-bottom: 10px !important;
}
.filter-container{
  row-gap: 10px;
}
.survey-error-text{
  color: red;
    font-size: 12px;
    text-align: left;
    width: 100%;
    margin-top: 5px;
}
.error-text{
  padding-left: 10px;
  margin-top: -18px !important;
  margin-bottom: 18px !important;
  color: red !important;
}
.select-error{
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.modal-close-icon{
  padding: 0 !important;
  margin: 0 !important
}
.cg-form-grid-padding{
  padding: 16px 10px 0 10px;
}
@media screen and (max-width: 1000px) {
  .modal-container {
    max-width: 50%;
  }
}
@media screen and (max-width: 700px) {
  .modal-container {
    max-width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .top-search-bar{
    margin: 10px 0;
  }
}
@media screen and (max-width: 400px) {
  .top-search-bar .MuiAutocomplete-inputRoot{
   width: 250px !important;
  }
}
@media screen and (max-width: 350px) {
  .top-search-bar .MuiAutocomplete-inputRoot{
   width: 200px !important;
  }
}
@media screen and (max-width: 900px) {
  .border-bottom-900px{
    border-bottom: 1px solid #E3E8EE;
    padding: 15px 0 !important;
  }
  .pad-r-10{
    padding: unset;
  }
  .mb-10 {
   margin-bottom: 20px !important;
  }
  .saved-list-container{
    row-gap: 10px !important
  }
  .saved-list-btns-container{
    margin-top: 10px !important;
  }
  .saved-list-hh-btns-container{
    margin-bottom: 10px !important;
  }
  .dashboard-goal-modal{
   height: 560px;
   overflow-y: scroll;
  }
}
@media screen and (max-width: 400px) {
  .modal-container {
    max-width: 80%;
  }
}

.of-scroll{
  overflow: scroll !important;
}

.mt-0{
  margin-top: 0 !important;
}

.cg-font-weight-bold{
  font-weight: bold;
}

.no-bg-btn{
  color: #2196f3;
  font-weight: 700;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  line-height: 1 !important;
  background: transparent !important;
  cursor: pointer;
}
.no-bg-btn:hover{
  background: transparent !important;
  color: #673ab7;
}

.landing-usp-caption {
  font-size: 18px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 1.3
}

.landing-usp-caption-sub {
  font-size: bold !important;
  font-size: 15px;
  color: rgb(124, 53, 165);
}

.capitalise-text {
  text-transform: capitalize;
}
.cg-login-container {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  min-height: 100vh;
  background-color: #eef2f6;
}

.cg-login-card {
  margin-top: -40px;
  padding: 24px;
  max-width: 400px;
  background: white;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  box-shadow: none;
  background-image: none;
  border-radius: 8px !important;
  overflow: hidden;
  border: none rgb(227, 232, 239) !important;
  padding-bottom: 35px;
}

.cg-theme-color {
  color: rgb(103, 58, 183);
}
.add-tag-from-card{
  border-color: #d7d7d7 !important;
  color: #3c3c3c !important;
  margin: 3px 0 !important;
  border-radius: 4px !important;
  
  &:hover {
    font-weight: 500;
  }
}

.hover-highlight-color:hover {
    color: #2196f3;
}

.cg-main-content-container {
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (min-width: 601px) {
    padding-left: 24px;
    padding-right: 24px;
    margin-right: 16px;
  }
  @media only screen and (min-width: 901px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 0px !important;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #7d7d7d transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #7d7d7d;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

.cg-menu-label {
  margin: 10px 15px 0.35em 15px;
  line-height: 1.66;
  display: block;
  color: rgb(18, 25, 38);
  font-size: 0.875rem;
  font-weight: 500;
  padding: 6px;
  text-transform: capitalize;
}

.cg-menu-divider {
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgb(227, 232, 239);
  margin-top: 15px !important;
}

.add-survey {
  padding: 4px 10px !important;
  white-space: nowrap;
}

.contacts-slider-view{
  height: calc(100vh - 150px);
  overflow: scroll;
}

.slider-contacts-section{
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}


.tag-text-field {
  .del-button {
    display: none;
  }
  &:hover {
    .del-button {
      display: inline-block;
    }
  }
}

.cg-content-cointainer {
  padding: 24px 10px;
  min-height: 75vh;
  border-radius: 10px;
  background-color: white;
  @media only screen and (min-width: 601px) {
    padding: 24px 24px;
  }
  @media only screen and (min-width: 901px) {
    padding: 24px 32px;
  }
}

.contact-search > :first-child {
  padding-left: 24px;
} 

.ac-txt-field > :first-child {
  min-height: 48px !important;
}  
.ac-txt-field-n > :nth-child(2) {
  min-height: 48px !important;
}
.ac-txt-field-n > :first-child {
  min-height: 48px !important;
}

.walk-list-detail-wrapper{
  height: calc(100vh - 180px);
  overflow: scroll !important;
}

.count-view-container{
    max-height: 80px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    /* border: 1px solid #E3E8EE; */
    margin-bottom: 16px !important;
    border-radius: 8px;
    background: linear-gradient(to right, #dad2ee, #8d65e6);
  }

.count-view-number{
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
}
.count-view-text{
  font-size: 12px;
  color: #fff;
}

.cg-filter-group-btn {
  padding: 5px 15px !important;
}

.cg-v-line-group {
  position: relative;
  padding-left: 10px;
  margin-left: 15px;
  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    background-color: #e9ecef;
    width: 2px;
    height: 100%;
    left: 0;
    top: 0;
  }
}


.filter-container {
  .cg-v-line-row-group {
    position: relative;
    padding-left: 20px;
    margin-left: 0px;
    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      background-color: #e9ecef;
      width: 2px;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &:only-child {
    .cg-v-line-row-group {
      padding-left: 0px;
      &::before {
        display: none;
      }
    }
    .cg-filter-row {
      &::before {
        display: none;
      }
    }
  }

  &:last-child {
    .cg-v-line-row-group {
      &::before {
        height: calc(100% - 23px);
      }
    }
  }

  .cg-filter-row {
    padding-right: 50px;
    position: relative;
    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      background-color: #e9ecef;
      width: 20px;
      height: 2px;
      bottom: 22px;
      left: -20px;
    }
  }
}

.cg-search-filter-container {
  border: 1px solid #E3E8EE;
  padding: 24px 10px;

  @media only screen and (min-width: 601px) {
    padding: 24px 16px;
  }
  @media only screen and (min-width: 901px) {
    padding: 24px 24px;
  }
}

.turf-cut-icon{
  position: absolute;
  top: 10px;
  right: 55px;
  background: #FFFFFF;
  cursor: pointer;
  padding: 8px;
  height: 40px;
  border-radius: 1px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  color: #666666;
}

.turf-cut-icon-border{
  border: 1px solid #1698BE;
  color: #1698BE !important;
}

.cg-dashboard-value-card {
  position: relative;
  /* background: rgb(94, 53, 177) !important; */
  padding: 18px;

  /* &::before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: rgb(69, 39, 160);
    border-radius: 50%;
    opacity: 0.5;
    top: -125px;
    right: -15px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: rgb(69, 39, 160);
    border-radius: 50%;
    top: -85px;
    right: -95px;
  } */

  &.card-blue {
    background: rgb(30, 136, 229) !important;
    &::before {
      background: rgb(21, 101, 192);
    }
    &::after {
      background: rgb(21, 101, 192);
    }
  }
}
.cg-household-dashboard-card {
  position: relative;
  background: rgb(94, 53, 177) !important;
  padding: 18px;
  border-radius: 3px;

  &::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: rgb(69, 39, 160);
    border-radius: 50%;
    opacity: 0.5;
    top: 0px;
    right: 0px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: rgb(69, 39, 160);
    border-radius: 50%;
    top: 20px;
    right: 5px;
  }

  &.card-blue {
    background: rgb(30, 136, 229) !important;
    &::before {
      background: rgb(21, 101, 192);
    }
    &::after {
      background: rgb(21, 101, 192);
    }
  }
}
.cg-household-dashboard-card {
  position: relative;
  background: #2196f3 !important;
  padding: 5px 10px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: #61b8ff;
    border-radius: 50%;
    opacity: 0.4;
    top: 35px;
    right: -10px;
  }
  &::after {
    content: "";
    opacity: 2.2;
    position: absolute;
    width: 50px;
    height: 50px;
    background: #309ff7;
    border-radius: 50%;
    top: -5px;
    right: -30px;
  }

  &.card-blue {
    background: rgb(30, 136, 229) !important;
    &::before {
      background: rgb(21, 101, 192);
    }
    &::after {
      background: rgb(21, 101, 192);
    }
  }
}

.cg-connections-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 20px 0px;
  max-height: 300px;
  overflow: scroll;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.cg-landing-hero-section-1 {
  background-color: #1f1f1f;
  padding: 30px 15px;
  @media only screen and (min-width: 601px) {
    padding: 50px 20px;
  }
  @media only screen and (min-width: 901px) {
    padding: 100px 0px;
  }
}

.cg-footer {
  background-color: #f7f7f7;
  padding: 20px 15px;
  font-size: 14px;
  @media only screen and (min-width: 601px) {
    padding: 30px 50px;
  }

  @media only screen and (min-width: 901px) {
    padding: 30px 80px;
  }
  .cg-footer-content {
    display: flex;
    justify-content: space-between;
  }
}